document.addEventListener('DOMContentLoaded', function () {
    if (null === document.querySelector('[data-clipboard-type]')) {
        return;
    }

    listType = document.querySelector('[data-clipboard-type]').getAttribute('data-clipboard-type')
    let button = new ClipboardCopyButton({selector: '[data-clipboard-copy]', attr: 'data-clipboard-copy', message: document.querySelector('[data-clipboard-message]').getAttribute('data-clipboard-message')});

    let shareModal = new Modal({
        'create': true,
        'className': 'share-modal'
    });

    new AjaxLink({
        selector: '*[data-modal]',
        targetElements: {
            '.share-modal > .modal-content .content' : 'main .content'
        },
        isTriggerReplace: false,
        callback: function() {
            shareModal.show();
        }
    });
})