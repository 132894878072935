// Styles
require('../sass/app.scss');

// Scripts
import Modal from './src/Modal';
import ClipboardCopyButton from './src/ClipboardCopyButton';
import AjaxFilter from './src/AjaxFilter';
import AjaxForm from './src/AjaxForm';
import AjaxLink from './src/AjaxLink';
import AjaxSelect from './src/AjaxSelect';
import EnhancedEcommerce from './src/EnhancedEcommerce';
import EnhancedEcommerceProduct from './src/EnhancedEcommerceProduct';
import Toast from './src/Toast';
import Loader from './src/Loader';

window.Modal = Modal;
window.ClipboardCopyButton = ClipboardCopyButton;
window.AjaxFilter = AjaxFilter;
window.AjaxForm = AjaxForm;
window.AjaxLink = AjaxLink;
window.AjaxSelect = AjaxSelect;
window.EnhancedEcommerce = EnhancedEcommerce;
window.EnhancedEcommerceProduct = EnhancedEcommerceProduct;
window.Toast = new Toast();

document.addEventListener('DOMContentLoaded', function () {
    window.Loader = new Loader();
});

require('./src/components/all');

require('./src/dynamic-script-load');
require('./src/enhanced-ecommerce');
require('./src/maier-header');
require('./src/menu-display');
require('./src/netreviews-slick');
require('./src/product-search');
require('./src/retailer-clock');
require('./src/widget-avis-verifies');