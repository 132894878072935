document.addEventListener('DOMContentLoaded', function () {
    if (null === document.querySelector('.maier-footer *[data-modal="true"]')) {
        return;
    }

    let footerModal = new Modal({
        'create': true,
        'className': 'maier-modal-footer'
    });

    new AjaxLink({
        selector: '.maier-footer *[data-modal="true"]',
        targetElements: {
            '.maier-modal-footer > .modal-content .content' : 'main .content'
        },
        isTriggerReplace: false,
        callback: function() {
            footerModal.show();
        }
    });
})