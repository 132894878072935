import 'slick-slider/slick/slick.css';
import '../sass/app.scss';

import $ from 'jquery';
global.$ = global.jQuery = $;

import 'slick-slider';

require('./front/components/all');
require('./front/form/all');
require('./front/widget/all');
require('./front/wrapper/all');